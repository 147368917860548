@import url('https://fonts.googleapis.com/css2?family=Allura&display=swap');

* {
    user-select: none;
}

.solid {
    background-color: #000000;
    transition: background-color 1s ease 0s;
    box-shadow: 0 0 4px grey;
}

h2 {
    font-size: 26px;
    letter-spacing: 2px;
    line-height: 40px;
    font-weight: 700;
}

h3 {
    font-size: 21px;
    letter-spacing: 2px;
    line-height: 32px;
    font-weight: 700;
}

h4 {
    font-size: 18px;
    letter-spacing: 2px;
    line-height: 27px;
    font-weight: 700;
}

h5 {
    font-size: 15px;
    letter-spacing: 2px;
    line-height: 23px;
    font-weight: 600;
}

p {
    line-height: 20px;
    font-size: 13px;
    font-weight: 400;
    margin: 0 0 10px;
    letter-spacing: 1px;
}

.footer-links li {
    display: inline;
    margin: 5px;
}

.loader {
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: 9999;
    background-color: white;
}

.lms-icon {
    width: 60px;
    height: 60px;
    margin: 20px 0px 0px 0px;
    float: right;
}

@media only screen and (max-width: 768px) {

    /* For mobile phones: */
    #teson_logo {
        margin: 25px auto;
    }
}

@media only screen and (max-width: 550px) {

    /* For mobile phones: */
    #teson_logo {
        margin: 25px auto;
    }

    #pixelshield-plans-cards {
        overflow: scroll;
        grid-template-columns: repeat(4, 1fr) !important;
        width: 100%;
    }
}

#services_dropdown {
    top: 64px !important;
    width: max-content !important;
}

#services_dropdown_mobile {
    top: 190px !important;
    width: max-content !important;
}

#products_dropdown {
    top: 64px !important;
    width: max-content !important;
}

#products_dropdown_mobile {
    top: 190px !important;
    width: max-content !important;
}

#pixelshield-plans {
    background-color: cadetblue;
    padding: 20px;
    margin: 20px 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#pixelshield-plans>h3,
#pixelshield-plans-cards>div>h3 {
    margin-top: 10px;
    margin-bottom: 20px;
}

#pixelshield-plans-cards {
    margin: 1%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    max-width: 1250px;
}

#pixelshield-plans-cards>div {
    padding: 10px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
}

#pixelshield-plans-cards>div>img {
    border-radius: 10px;
    /* width: 100%; */
}

#modal-pixelshield-plan.modal.modal-fixed-footer {
    top: 25% !important;
    height: 450px !important;
    background-color: #fff;
    width: 350px !important;
}

#modal-pixelshield-plan .input-field.col label {
    left: 0 !important;
}

#modal-pixelshield-plan .modal-content {
    display: flex;
    flex-direction: column;
}

#modal-pixelshield-plan .modal-content>div {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#modal-pixelshield-plan .modal-footer {
    display: flex;
    justify-content: space-between;
    padding: 10px 24px;
}

#modal-pixelshield-plan .modal-footer>button,
#modal-pixelshield-plan>.modal-footer>a {
    margin: 0px;
}